<template>
  <div>
    <EditProfile />
  </div>
</template>

<script>
import EditProfile from '@/components/profile/EditProfile'

export default {
  components: { EditProfile },
  data () {
    return { }
  }
}
</script>
