<template>
  <b-row align-h="center">
    <b-col xs="12" xl="4" >
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <!-- Pais -->
        <b-form-group id="input-group-2" :label="$t('PROFILE.FORM.country')" label-for="input-2">
          <b-form-select
            id="input-country"
            v-model="form.country"
            :options="countries"
            :state="isCountryValid"
            required
          ></b-form-select>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback :id="`input-country-feedback`">
            {{ $t('FORMS.required') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Idioma -->
        <b-form-group id="input-group-3" :label="$t('PROFILE.FORM.language')" label-for="input-3">
          <b-form-select
            id="input-language"
            v-model="form.language"
            :options="langs"
            text-field="name"
            value-field="lang"
            :state="isLanguageValid"
            required
          ></b-form-select>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback :id="`input-language-feedback`">
            {{ $t('FORMS.required') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-button type="submit" variant="primary" :disabled="!isFormValid" block>{{ $t('ACTIONS.send') }}</b-button>
        <b-button type="reset" variant="danger" block>{{ $t('ACTIONS.reset') }}</b-button>
      </b-form>
    </b-col>
    <loading :loading="loading" />
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ApiService from '@/services/api.service'
import URLS from '@/config/urls'
import ToastsMixin from '@/services/mixins/toasts.mixins'

import i18nservice from '@/services/i18n.service'

export default {
  name: 'EditProfile',
  mixins: [ToastsMixin],
  data () {
    return {
      loading: false,
      form: {
        country: '',
        language: ''
      },
      countriesList: [{ text: 'Spain', value: 'es' }],
      show: true
    }
  },
  methods: {
    ...mapActions({
      getUser: 'auth/getUser'
    }),
    onSubmit (evt) {
      evt.preventDefault()
      this.loading = true
      return ApiService.post(URLS.API.USER.UPDATE_STUDENT, this.form)
        .then(_ => {
          i18nservice.setActiveLanguage(this.form.language)
          this.$root.$i18n.locale = this.form.language
          this.$forceUpdate()
          this.getUser()
        })
        .then(_ => {
          this.showSuccessToast(this.$t('FORMS.update_success'))
        })
        .catch(_ => {
          this.showErrorToast(this.$t('FORMS.ERRORS.generic'))
        })
        .then(_ => { this.loading = false })
    },
    onReset (evt) {
      if (evt) evt.preventDefault()

      // Reset our form values
      this.form.country = this.user.country
      this.form.language = this.user.language

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  watch: {
    user: {
      deep: true,
      handler () {
        this.onReset()
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser'
    }),
    isCountryValid () {
      if (typeof this.form.country === 'undefined' || this.form.country === '') return null
      return this.form.country !== null
    },
    isLanguageValid () {
      if (typeof this.form.language === 'undefined' || this.form.language === '') return null
      return this.form.language !== null
    },
    isFormValid () {
      return this.isCountryValid && this.isLanguageValid
    },
    fullName () {
      return `${this.user.name} ${this.user.surname}`
    },
    langs () {
      return i18nservice.languages.map(i => {
        return { ...i, name: this.$t(`GENERAL.LANGS.${i.name}`) }
      })
    },
    countries () {
      return [
        { text: this.$t('GENERAL.select_one'), value: null },
        ...this.countriesList.map(i => { return { ...i, text: this.$t(`GENERAL.COUNTRIES.${i.text}`) } })
      ]
    }
  },
  mounted () {
    this.onReset()
  }
}
</script>

<style lang="scss">
label {
  color: var(--primary);
}
</style>
